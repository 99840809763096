<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import plLocale from '@fullcalendar/core/locales/pl';

import {BCard, BCardText, BCollapse, BButton, BCardHeader, BCardBody, BModal, BFormCheckbox} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import Quiz from './quiz/Quiz.vue';


export default {
  components: {
    BCard,
    BCardText,
    BFormCheckbox,
    BCollapse,
    BButton,
    BCardHeader,
    BCardBody,
    quillEditor,
    Quiz,
    BModal,
    FullCalendar
  },
  data() {
    return {
      course: {},
      newsletterAgreement: 0,
      newsletterState: null,
      currentEvent: {
        id: 0,
        title: "",
        content: "",
        date: "",
        is_done: false,
        info: {}
      },
      editorOption: {
        theme: 'snow',
        placeholder: 'Miejsce na Twoje notatki',
        modules: {
          toolbar: '#toolbar-container'
        },
      },
      calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin, listPlugin ],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,listMonth',
        },
        initialDate: "2022-02-01",
        fixedWeekCount: false,
        locale: plLocale,
        events: [],
        eventDisplay: 'block',
        eventColor: '#1facbb',
        eventClick: this.handleDateClick,
      },
      note: ''
    }
  },
  computed: {
    lessonType() {
      if (typeof this.$store.getters['app/getCurrentCourse'] !== 'undefined') return this.$store.getters['app/getCurrentCourse'].type

      return 'lesson'
    },
    lessonTitle() {
      if (typeof this.$store.getters['app/getCurrentCourse'] !== 'undefined') return this.$store.getters['app/getCurrentCourse'].title

      return ''
    },
    lessonContent() {
      if (typeof this.$store.getters['app/getCurrentCourse'] !== 'undefined') return this.$store.getters['app/getCurrentCourse'].content

      return ''
    },
    lessonNotate() {
      return this.$store.getters['app/getCurrentCourse'].note
    },

    lesson() {
      if (typeof this.$store.getters['app/getCurrentCourse'] === 'undefined')
        return '';
      return this.$store.getters['app/getCurrentCourse'];
    },
    lessonChallengeEvents() {
      if (typeof this.$store.getters['app/getCurrentCourse'].challenge_items !== 'undefined') {
        return this.$store.getters['app/getCurrentCourse'].challenge_items.map((item) => {
          return {
            id: item.id,
            title: item.user_challenge_done ? "✔ " + item.name : item.name,
            date: item.date,
            content: item.content,
            is_done: item.user_challenge_done,
            color: item.user_challenge_done ? '#1FACBB' : '#3AC7D6'
          }
        });
      }
      return []
    },
  },
  watch: {
    lessonNotate(newNotate, oldNotate) {
      this.note = newNotate;
    },
    lessonChallengeEvents(newEvents, oldEvents) {
      this.calendarOptions.events = newEvents;
      this.calendarOptions.dateClick = this.handleDateClick;
    },
  },
  mounted() {
    // this.$emit('changeRouteTitle', 'nameOfYourPage')
  },
  created() {
    this.fetchCourse(this.$route.params.slug)
  },
  methods: {
    handleDateClick: function(info) {
      info.jsEvent.preventDefault();

      this.currentEvent = {
        id: info.event._def.publicId,
        title: info.event._def.title,
        content: info.event._def.extendedProps.content,
        is_done: info.event._def.extendedProps.is_done,
        date: info.event._def.date,
        info: info
      };
      this.$refs['event-modal'].show();
    },
    fetchCourse(slug) {
      this.$http.get(`https://${this.$store.state.api_domain}/api/my/course/${slug}`).then(response => {

        this.course = response.data;

        this.$store.commit('app/UPDATE_CUSTOM_LAYOUT_MENU', response.data.sections)

        let currentLesson = null;
        response.data.sections.forEach(function (section){
          section.lessons.forEach(function (lesson){
            if(!lesson.user_done && currentLesson === null)
            {
              currentLesson = lesson;
            }
          });
        })

        this.$store.commit('app/UPDATE_CURRENT_COURSE', currentLesson === null ? response.data.sections[0].lessons[0] : currentLesson)
        this.showNewsletter();
      })
    },
    showNewsletter(){
      if(this.course.show_newsletter)
      {
        this.$refs['newsletter-modal'].show();
      }
    },
    appendArrow() {
      this.note += "→";
    },
    saveData() {
      const data = {
        lesson_id: this.$store.getters['app/getCurrentCourse'].id,
        note: this.note,
      }

      this.$http.post(`https://${this.$store.state.api_domain}/api/lesson/set-note`, data).then(response => {
        if (response.status === 200) {
          this.$bvToast.toast('Notatka zapisana pomyślnie', {
            title: 'Zapis',
            variant: 'success',
          })
        } else {
          this.$bvToast.toast('Wystąpił błąd podczas zapisu notatki', {
            title: 'Zapis',
            variant: 'danger',
          })
        }
      })
    },
    changeStatusForDone() {
      this.currentEvent.info.el.innerHTML =
          "<div class=\"fc-event-main\">" +
            "<div class=\"fc-event-main-frame\">" +
              "<div class=\"fc-event-title-container\">" +
                "<div class=\"fc-event-title fc-sticky\">" +
                  "✔ " + this.currentEvent.title.replace("✔ ", '') +
                "</div>" +
              "</div>" +
            "</div>" +
          "</div>";
      this.currentEvent.info.el.style.backgroundColor = '#1FACBB';
      this.currentEvent.info.el.style.color = '#ffffff';
    },
    challengeMakeDone() {
      const data = {
        challenge_item_id: this.currentEvent.id,
        is_done: true,
      }

      this.$http.post('https://api.tomaszkrawczyk.pl/api/lesson/toggle-challenge-done', data).then(response => {
        if (response.status === 200) {
          this.$bvToast.toast('Zadanie zakończone', {
            title: 'Zapis',
            variant: 'success',
          })
          this.$refs['event-modal'].hide();
          this.changeStatusForDone();
        } else {
          this.$bvToast.toast('Wystąpił błąd podczas połączenia do serwera', {
            title: 'Zapis',
            variant: 'danger',
          })
        }
      })
    },
    newsletter(accepted) {
      if(!this.newsletterAgreement && accepted) {
        this.newsletterState = false;
        return ;
      }

      const data = {
        course_id: this.course.id,
        accepted: accepted,
      }

      this.$http.post('https://api.tomaszkrawczyk.pl/api/course/toggle-newsletter', data).then(response => {
        if (response.status === 200) {
          this.$refs['newsletter-modal'].hide();
        }
      })
    },
  }
}
</script>

<template>
  <div>
    <div class="lesson-content" v-if="lessonType == 'quiz'">
      <b-card :title="lessonTitle">
        <b-card-text>
          <Quiz :quiz="lesson.quiz" :refillable="true"></Quiz>
        </b-card-text>
      </b-card>
    </div>
    <div class="lesson-content" v-if="lessonType == 'challenge'">
      <b-card :title="lessonTitle">
        <b-card-text v-html="lessonContent">
          {{ lessonContent }}
        </b-card-text>
        <FullCalendar ref="calsession" :options="calendarOptions" />

        <b-modal ref="event-modal" hide-footer :title="currentEvent.title">
          <div class="d-block" v-html="currentEvent.content"></div>
          <b-button class="mt-2" variant="outline-primary" block @click="challengeMakeDone">Zadanie wykonane</b-button>
        </b-modal>
      </b-card>
    </div>
    <div class="lesson-content" v-if="lessonType == 'lesson'">
      <b-card :title="lessonTitle">
        <p v-if="!lessonContent">
          Skorzystaj z menu by wybrać lekcję lub kliknij w logo TomaszKrawczyk by wrócić do strony głównej.
        </p>
        <b-card-text v-html="lessonContent">
          {{ lessonContent }}
        </b-card-text>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-body>
          <h4 class="card-title">Twoje notatki</h4>
          <div id="toolbar-container">
          <span class="ql-formats">
            <button type="button" class="ql-bold"><svg viewBox="0 0 18 18"> <path class="ql-stroke" d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"></path> <path class="ql-stroke" d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"></path> </svg></button>
            <button type="button" class="ql-italic"><svg viewBox="0 0 18 18"> <line class="ql-stroke" x1="7" x2="13" y1="4" y2="4"></line> <line class="ql-stroke" x1="5" x2="11" y1="14" y2="14"></line> <line class="ql-stroke" x1="8" x2="10" y1="14" y2="4"></line> </svg></button>
            <button type="button" class="ql-underline"><svg viewBox="0 0 18 18"> <path class="ql-stroke" d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path> <rect class="ql-fill" height="1" rx="0.5" ry="0.5" width="12" x="3" y="15"></rect> </svg></button>
          </span>
            <span class="ql-formats">
            <button type="button" class="ql-list" value="ordered"><svg viewBox="0 0 18 18"> <line class="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> <line class="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> <line class="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> <line class="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> <path class="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> <path class="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> <path class="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> </svg></button><button type="button" class="ql-list" value="bullet"><svg viewBox="0 0 18 18"> <line class="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> <line class="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> <line class="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> <line class="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> <line class="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> <line class="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> </svg></button>
          </span>
            <span class="ql-formats">
            <button type="button" class="ql-script" value="sub"><svg viewBox="0 0 18 18"> <path class="ql-fill" d="M15.5,15H13.861a3.858,3.858,0,0,0,1.914-2.975,1.8,1.8,0,0,0-1.6-1.751A1.921,1.921,0,0,0,12.021,11.7a0.50013,0.50013,0,1,0,.957.291h0a0.914,0.914,0,0,1,1.053-.725,0.81,0.81,0,0,1,.744.762c0,1.076-1.16971,1.86982-1.93971,2.43082A1.45639,1.45639,0,0,0,12,15.5a0.5,0.5,0,0,0,.5.5h3A0.5,0.5,0,0,0,15.5,15Z"></path> <path class="ql-fill" d="M9.65,5.241a1,1,0,0,0-1.409.108L6,7.964,3.759,5.349A1,1,0,0,0,2.192,6.59178Q2.21541,6.6213,2.241,6.649L4.684,9.5,2.241,12.35A1,1,0,0,0,3.71,13.70722q0.02557-.02768.049-0.05722L6,11.036,8.241,13.65a1,1,0,1,0,1.567-1.24277Q9.78459,12.3777,9.759,12.35L7.316,9.5,9.759,6.651A1,1,0,0,0,9.65,5.241Z"></path> </svg></button>
            <button type="button" class="ql-script" value="super"><svg viewBox="0 0 18 18"> <path class="ql-fill" d="M15.5,7H13.861a4.015,4.015,0,0,0,1.914-2.975,1.8,1.8,0,0,0-1.6-1.751A1.922,1.922,0,0,0,12.021,3.7a0.5,0.5,0,1,0,.957.291,0.917,0.917,0,0,1,1.053-.725,0.81,0.81,0,0,1,.744.762c0,1.077-1.164,1.925-1.934,2.486A1.423,1.423,0,0,0,12,7.5a0.5,0.5,0,0,0,.5.5h3A0.5,0.5,0,0,0,15.5,7Z"></path> <path class="ql-fill" d="M9.651,5.241a1,1,0,0,0-1.41.108L6,7.964,3.759,5.349a1,1,0,1,0-1.519,1.3L4.683,9.5,2.241,12.35a1,1,0,1,0,1.519,1.3L6,11.036,8.241,13.65a1,1,0,0,0,1.519-1.3L7.317,9.5,9.759,6.651A1,1,0,0,0,9.651,5.241Z"></path> </svg></button>
          </span>
            <span class="ql-formats">
            <button type="button" @click="appendArrow()">→</button>
          </span>
          </div>
          <quill-editor
              v-model="note"
              :options="editorOption"
          />
          <b-button
              variant="success"
              @click="saveData()"
              class="mt-2"
          >
            Zapisz notatkę
          </b-button>
        </b-card-body>
      </b-card>
    </div>
    <div>
      <b-modal ref="newsletter-modal" no-close-on-backdrop hide-footer size="lg" title="Newsletter" @close="newsletter(false)">
        <div class="d-block" v-html="course.newsletter_popup_content"></div>
        <div class="d-block">
          <b-form-checkbox
              id="checkbox-1"
              name="checkbox-1"
              v-model="newsletterAgreement"
              :state="newsletterState"
              value="1"
              unchecked-value="0"
              required
          >
            Wyrażam zgodę na przetwarzanie danych osobowych oraz otrzymywanie newslettera i informacji marketingowych drogą e-mailową od Administratora danych: Sonia Radosz, prowadzącą działalność gospodarczą pod firmą „TomaszKrawczyk Sonia Radosz” pod adresem: ul. Wodzisławska 4, 44-240 Żory, NIP: 6381739715. Szczegóły dotyczące przetwarzania danych znajdziesz w <a href="/polityka-prywatnosci/" target="_blank">Polityce Prywatności</a>.
          </b-form-checkbox>
        </div>
        <b-button class="mt-2" variant="outline-primary" block @click="newsletter(true)">Zapisz się do newslettera</b-button>
      </b-modal>
    </div>
  </div>
</template>

<style>
  img, iframe{
    max-width: 100%;
    height: auto;
  }
  .fc-daygrid-event{
    white-space:normal!important;
  }
</style>
<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
// prevent copying of text
/*.lesson-content {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}*/
</style>
