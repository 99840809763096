var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"question py-2 my-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-9"},[_c('div',{staticClass:"question__body mb-2"},[_c('span',{staticClass:"quiz-black",domProps:{"innerHTML":_vm._s(_vm.question.title)}},[_vm._v(_vm._s(_vm.question.title))])])]),_c('div',{staticClass:"col-sm-3"},[(_vm.feedback && _vm.feedback.option_id)?_c('div',[_c('h3',{class:{
          'text-success text-right': _vm.feedback.points != 0,
          'text-danger text-right': _vm.feedback.points == 0,
        }},[_vm._v(" "+_vm._s(_vm.feedback.points)+"/1 ")])]):_vm._e()])]),_vm._l((_vm.options),function(option,idx){return _c('div',{key:idx,staticClass:"custom-control custom-radio"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","id":option.id,"name":_vm.question.id,"disabled":_vm.is_submitted},domProps:{"value":option.id,"checked":_vm.feedback && _vm.feedback.option_id === option.id},on:{"change":function($event){return _vm.$emit('input',{
                  question_id: _vm.question.id,
                  option_id: option.id
              })}}}),_c('label',{staticClass:"option__label",class:{
            'text-danger': _vm.feedback && _vm.feedback.option_id == option.id && _vm.feedback.points == 0,
            'text-success': _vm.feedback && _vm.feedback.option_id == option.id && _vm.feedback.points != 0
        },attrs:{"for":option.id}},[_c('span',{staticClass:"d-inline-block quiz-black",domProps:{"innerHTML":_vm._s(option.content.replace("color:", "").replace("background-color:", "").replace("background:", ""))}}),(_vm.feedback && _vm.feedback.correct_option_id == option.id)?_c('strong',{staticClass:"text-success d-inline-block pl-1"},[_vm._v(" Poprawna odpowiedź ")]):_vm._e()])])}),(_vm.feedback && _vm.feedback['explanation'])?_c('b-card',{staticClass:"border mt-2"},[_c('b-card-title',{staticClass:"text-warning font-weight-bold"},[_vm._v("Komentarz:")]),_c('b-card-text',{staticClass:"quiz-black",domProps:{"innerHTML":_vm._s(_vm.feedback["explanation"].replace("color:", "").replace("background-color:", "").replace("background:", ""))}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }