<template>
    <div>
        <Radiogroup v-for="(question,idx) in quiz.questions"
        :question="question"
        :feedback="answer[question.id]"
        :is_submitted="true"
        ></Radiogroup>
    </div>
</template>
<script>
import Radiogroup from "./Radiogroup.vue";

export default {
    props:["quiz","answer"],
    components: {
        Radiogroup,
    },
    data() {
        return {
            questions: [],
        }
    },

    mounted() {
        let keys = Object.keys(this.answer);

        for(let key in keys) {
            if(key !== "total_points")
                this.questions.push(this.answer[key]);
        }
    }
}
</script>
<style lang="">
    
</style>
